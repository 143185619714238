import { inject, Injectable, Injector } from '@angular/core';
import { Setting } from '@common/interfaces/setting.interface';
import { QueryService } from '@common/services/query.service';
import { DialogService } from './dialog.service';
import { ToastrNotificationService } from './toastr-notification.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    injector = inject(Injector);
    constructor(
        private queryService: QueryService,
        private toastrNotificationService: ToastrNotificationService,
        private dialogService: DialogService
    ) {}

    getSettings(settingKeys?: string[], categoryId?: string) {
        return this.queryService.getQuery<Setting[]>(
            'Settings',
            { settingKeys, categoryId },
            { queryKey: ['settings'], staleTime: Infinity, injector: this.injector }
        ).result$;
    }

    getLoginSettings() {
        return this.queryService.getQuery<Setting[]>(
            'ApplicationSettingsOnLogin',
            {},
            { queryKey: ['loginSettings'], injector: this.injector }
        ).result$;
    }

    getGridSettings() {
        return this.queryService.getQuery<Setting[]>(
            'GridSettings',
            {},
            { queryKey: ['gridSettings'], staleTime: Infinity, injector: this.injector }
        ).result$;
    }

    saveApplicationSettings(settings: Setting[]): Promise<unknown> {
        return this.queryService
            .getCommandMutation()
            .mutateAsync({
                command: 'SaveApplicationSettings',
                data: { settings },
                invalidate: ['settings', 'loginSettings']
            });
    }

    saveUserSettings(settings: Setting[]): Promise<unknown> {
        return this.queryService
            .getCommandMutation()
            .mutateAsync({
                command: 'SaveUserSettings',
                data: { settings },
                invalidate: ['settings', 'loginSettings']
            });
    }

    async deleteImageSetting(setting: Setting, isUserSetting: boolean = false) {
        const res = await this.dialogService.confirm({
            options: {
                title: 'Delete Image',
                message: 'Are you sure you want to delete this image?'
            }
        });
        if (!res) return;
        this.queryService
            .getCommandMutation()
            .mutateAsync({
                command: 'DeleteImageSetting',
                data: { settingKey: setting.key, isUserSetting },
                invalidate: ['settings', 'loginSettings']
            })
            .then(() => {
                this.toastrNotificationService.show({ type: 'success', message: 'Delete successful' });
                setting.imageModel = null;
                setting.value = null;
            });
    }
}
