<app-input-label
    [label]="label"
    [labelPosition]="labelPosition"
    [for]="id"
    [isRequired]="isRequired"
    [characterCount]="characterCount"
    [focused]="focused()">
    @if (isEditMode) {
        <kendo-textarea
            [id]="id"
            [title]="title | translate"
            [placeholder]="placeholder"
            [maxlength]="maxLength"
            [disabled]="isDisabled"
            [required]="isRequired"
            [ngClass]="generateInputStyle()"
            [rows]="rows"
            [(ngModel)]="value"
            (valueChange)="onValueChange($event)"
            (inputFocus)="onFocusIn()"
            (inputBlur)="onFocusOut()" />
    } @else {
        <div class="whitespace-pre-wrap pb-1 pt-1.5">{{ value() }}</div>
    }
</app-input-label>
