import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    effect,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { GridSettings } from '@common/interfaces/grid-settings.interface';
import { resolveProperty } from '@common/models/util';
import { ValueOf } from '@common/models/util.interface';
import { ViewMode } from '@common/models/view-mode';
import { CommonService } from '@common/services/common.service';
import { CompactModeService } from '@common/services/compact-mode.service';
import { environment } from '@environments/environment';
import { tapSuccessResult } from '@ngneat/query';
import {
    CellClickEvent,
    CellCloseEvent,
    ColumnBase,
    ColumnComponent,
    DataStateChangeEvent,
    DetailExpandEvent,
    DetailTemplateDirective,
    GridComponent,
    GridDataResult,
    GridSize,
    PageChangeEvent,
    PagerSettings,
    RowArgs,
    RowClassArgs,
    RowClassFn,
    ScrollMode,
    SelectableSettings,
    SortSettings,
    ToolbarTemplateDirective
} from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import _ from 'lodash';
import { combineLatest, debounceTime, Subject, take, takeUntil } from 'rxjs';
import { ActionBarGroup } from '../action-bar/action-bar.interface';

/**
 * Our grid wrapper
 *
 * You can project items into the toolbar like so:
 * ```html
 * <app-grid ...>
 *   <kendo-dropdownlist toolbar/>
 * </app-grid>
 * ```
 */
@Component({
    selector: 'app-grid',
    templateUrl: './app-grid.component.html',
    styleUrls: ['./app-grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    private destroy$ = new Subject<boolean>();
    public id = _.uniqueId('app-grid-');
    gridData: GridDataResult = { data: [], total: 0 };
    state: State = { skip: 0 };
    _cols: ColumnSettings[] = [];

    @ContentChild(ToolbarTemplateDirective) protected toolbar: ToolbarTemplateDirective;
    @ContentChild(ToolbarTemplateDirective) protected toolbarCenter: ToolbarTemplateDirective;
    @ContentChild(DetailTemplateDirective) public detailTemplate: DetailTemplateDirective;
    @ContentChildren(ColumnBase) public columns = new QueryList<ColumnBase>();

    @ViewChild(GridComponent) public grid: GridComponent;
    @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

    @Input({ required: true }) data: GridDataResult | any[] | any;
    @Input() buttons: ActionBarGroup[];
    @Input() filterable = environment.settings.grid.filterable;
    @Input() reorderable = environment.settings.grid.reorderable;
    @Input() resizable = environment.settings.grid.resizable;
    @Input() autoFitColumns = environment.settings.grid.autoFitColumns && this.resizable;
    @Input() sortable = environment.settings.grid.sortable as SortSettings;
    @Input() sort = [...environment.settings.grid.sort] as SortDescriptor[];
    @Input() customSort: { field: string; sortFn: (row: any) => any };
    @Input() isLocalSortDisabled: boolean;
    @Input() selectable: boolean | SelectableSettings;
    @Input() scrollable: ScrollMode = 'scrollable';
    @Input() selectBy: 'row' | string = 'id';
    @Input() gridSize: GridSize = 'medium';
    @Input() pageSize: number = environment.settings.grid.pageSize;
    @Input() selection = [];
    @Input() pageable: PagerSettings | boolean = environment.settings.grid.pageable as PagerSettings;
    @Input() isBusy: boolean;
    @Input() rowClass: RowClassFn = (context: RowClassArgs) => null;
    @Input() nrOfActiveFilters: number;
    @Input() set cols(value: ColumnSettings[]) {
        this._cols = value.map((col, index) => ({
            type: 'string',
            isVisible: true,
            isSortable: true,
            width: col.width === undefined ? (index === value.length - 1 ? null : 100) : col.width,
            codelistTooltip: col.type === 'codelist',
            ...col
        }));
    }
    get cols(): ColumnSettings[] {
        return this._cols;
    }
    @Input() parentRoute: string;
    @Input() defaultViewMode: ValueOf<typeof ViewMode> = ViewMode.view;
    @Input() linkUrlOverride: string = null;
    @Input() queryData: (grid?: GridComponent) => void = () => null;
    @Input() allowGridSave: boolean = true;
    @Input() gridSettingFilter: any;
    @Input() showColumnSelector: boolean = true;
    @Input() applyLocalPagination: boolean;

    @Output() selectedKeysChange = new EventEmitter<any[]>();
    @Output() pageChange = new EventEmitter<PageChangeEvent>();
    @Output() sortChange = new EventEmitter<SortDescriptor[]>();
    @Output() cellClick = new EventEmitter<CellClickEvent>();
    @Output() cellClose = new EventEmitter<CellCloseEvent>();
    @Output() toggleFilter = new EventEmitter();
    @Output() clearFilter = new EventEmitter();
    @Output() saveGridSettings = new EventEmitter();
    @Output() deleteGridSettings = new EventEmitter();
    @Output() expandDetailTemplate = new EventEmitter<DetailExpandEvent>();
    isCompactMode = inject(CompactModeService).isCompactMode;

    public selectionKey(context: RowArgs): string {
        return context.dataItem.id;
    }

    public gridSettings: GridSettings;
    public resolveProperty = resolveProperty;
    saveAndLoadButtonsVisible = false;
    settingsCleared = false;
    private _savedStateExists: boolean;

    public get savedStateExists(): boolean {
        return this._savedStateExists;
    }

    public set savedStateExists(value: boolean) {
        this._savedStateExists = value;
    }

    public get isSelectable(): boolean {
        return (
            (typeof this.selectable === 'boolean' && this.selectable) ||
            (this.selectable as SelectableSettings)?.enabled
        );
    }

    public get showSelectAll(): boolean {
        const selectableSettings = this.selectable as SelectableSettings;
        return (
            (typeof this.selectable === 'boolean' && this.selectable) ||
            (selectableSettings.enabled && selectableSettings.mode !== 'single')
        );
    }

    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected commonService: CommonService,
        protected router: Router,
        private ngZone: NgZone
    ) {
        effect(() => {
            this.isCompactMode();
            setTimeout(() => this.grid.autoFitColumns(), 0);
        });
    }

    ngOnInit() {
        if (this.allowGridSave) this.loadGridSettingsOnRouteReady();
        this.state.take = this.gridSettings?.state?.take || this.pageSize;
        this.state.sort = this.gridSettings?.state?.sort || this.sort;
    }

    ngAfterViewInit() {
        this.grid.toolbarTemplate = this.toolbar;
        this.grid.detailTemplate = this.detailTemplate;
        if (this.gridSettings.columnsConfig.length > 0) this.columns = this.grid.columns;
        this.updateColumns();
        this.columns.changes.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateColumns());
        if (this.selectBy !== 'id') this.changeSelection();
        if (this.autoFitColumns) setTimeout(() => this.fitColumns(), 1000);
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isBusy) this.isBusy = changes.isBusy.currentValue;

        if (!changes.data) return;

        if (changes.sort) this.state.sort = this.sort;

        if (changes.data?.currentValue) {
            const currentValue = changes.data.currentValue;
            if (Array.isArray(currentValue)) {
                this.gridData = { data: currentValue, total: currentValue.length };
                this.processGridData(this.gridData.data, this.state);
            } else if ('total' in currentValue && 'data' in currentValue) {
                this.gridData = { data: currentValue.data, total: currentValue.total };
            } else if (currentValue?.isSuccess) {
                this.gridData = { data: currentValue.data.results, total: currentValue.data.inlineCount };
            } else {
                this.gridData = { data: [], total: 0 };
            }
        }

        // if all the columns are hidden, don't show grid data in order not to show the columns by default
        if (this.data && this.cols.length > 0 && this.cols.every((col) => !col.isVisible)) {
            this.gridData = { data: [], total: 0 };
        }

        this.gridSettings = {
            state: this.settingsCleared ? this.state : (this.gridSettings?.state ?? this.state),
            columnsConfig: this.settingsCleared ? this.cols : (this.gridSettings?.columnsConfig ?? this.cols)
        };

        if (this.autoFitColumns) this.fitColumns();
    }

    public onSortChanged(sort: SortDescriptor[]): void {
        this.saveAndLoadButtonsVisible = true;
        this.sort = sort;
        if (this.gridData.total > this.state.take || this.isLocalSortDisabled) {
            this.queryData();
            this.sortChange.emit(sort);
        } else this.applyLocalSort();
    }

    onSaveGridSettings() {
        const gridSettings = {
            state: this.state,
            columnsConfig: this.columns
                .toArray()
                .filter((col: ColumnComponent) => col.field) // do not save columns without field(in case of selectable column)
                .map((item: ColumnComponent) => {
                    const columnMetadata = this.cols.find((x) => x.field === item.field) || {};
                    return <ColumnSettings>{
                        field: item.field,
                        title: item.title,
                        orderIndex: item.orderIndex,
                        isHidden: item.hidden,
                        style: item.style,
                        isSortable: item.sortable,
                        isVisible: true,
                        ...columnMetadata
                    };
                })
        };
        this.saveGridSettings.emit({ value: gridSettings, filter: this.gridSettingFilter });
        this.saveAndLoadButtonsVisible = false;
        this.settingsCleared = false;
        this._savedStateExists = true;
    }

    public loadGridSettings(): void {
        this.commonService.settingsService
            .getGridSettings()
            .pipe(
                takeUntil(this.destroy$),
                tapSuccessResult((res) => {
                    if (!res) return;
                    const gridSetting = res.find(
                        (x) => x.key === this.commonService.getSanitizedUrl(this.gridSettingFilter)
                    );
                    if (!gridSetting) return;
                    this.gridSettings = this.mapGridSettings(JSON.parse(gridSetting.value));
                    this.savedStateExists = true;
                    this.saveAndLoadButtonsVisible = false;
                    this.queryData();
                })
            )
            .subscribe();
    }

    public clearGridSettings(): void {
        this.settingsCleared = true;
        this.gridSettings.columnsConfig = null;
        this.saveAndLoadButtonsVisible = false;
        this.queryData();
        this.deleteGridSettings.emit(this.gridSettingFilter);
        this._savedStateExists = false;
    }

    public onColumnChange(): void {
        this.saveAndLoadButtonsVisible = true;
    }

    public showTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;

        if (typeof element.className !== 'string') return;
        if (Array.from(element.children).find((child) => !child.classList.contains('k-checkbox'))) return;
        if (element.hasAttribute('kendotooltip')) return; //* show only the custom codelist tooltip

        const parentElement = element.closest('td');
        if (
            (element.nodeName === 'TD' ||
                element.className?.includes('k-column-title') ||
                element.closest('td') !== null) &&
            (element.offsetWidth < element.scrollWidth || parentElement?.offsetWidth < parentElement?.scrollWidth)
        ) {
            this.tooltipDir.toggle(element);
        } else {
            this.tooltipDir.hide();
        }
    }

    applyLocalSort() {
        const sort = this.sort[0];
        if (!sort.dir) {
            this.gridData.data = orderBy(this.gridData.data, environment.settings.grid.sort as SortDescriptor[]);
        } else if (this.customSort && sort.field === this.customSort.field) {
            const sorted = this.gridData.data.sort(this.customSort.sortFn);
            this.gridData.data = sort.dir === 'desc' ? sorted.reverse() : sorted;
        } else {
            this.gridData.data = orderBy(this.gridData.data, this.sort);
        }
    }

    onPageChange(event: PageChangeEvent) {
        this.saveAndLoadButtonsVisible = true;
        this.pageChange.emit(event);
        this.queryData();
    }

    // On detail expanded event close all other rows
    onDetailExpanded(event: DetailExpandEvent) {
        this.gridData.data.forEach((row, index) => {
            if (index !== event.index) this.grid.collapseRow(index);
        });
        this.expandDetailTemplate.emit(event);
    }

    addColumn(column: ColumnBase, index?: number) {
        const columns = this.columns.toArray();
        if (index === undefined) columns.push(column);
        else columns.splice(index, 0, column);

        this.columns.reset(columns);
    }

    updateColumns(columns: ColumnBase[] = this.columns?.toArray() || []) {
        this.grid.columns.reset(columns);
        this.changeDetectorRef.detectChanges();
    }

    onDataStateChanged(state: DataStateChangeEvent) {
        if (state) this.state = state;

        if (this.applyLocalPagination) {
            let dataArray: any[] = [];

            if (this.data?.data?.results) dataArray = this.data.data.results;
            else dataArray = Array.isArray(this.data) ? this.data : [this.data];

            this.processGridData(dataArray, state);
        }

        if (this.autoFitColumns) this.fitColumns();
    }

    mapGridSettings(gridSettings: GridSettings): GridSettings {
        this.state = gridSettings.state;

        const columnsConfig = gridSettings.columnsConfig
            .sort((a: any, b: any) => a.orderIndex - b.orderIndex)
            .map((col) => {
                // Restore functions (e.g., click, indicator, conditionalClass) from the original column definitions
                // because functions cannot be serialized and are not saved in the JSON.
                const originalColumn = this.cols.find((x) => x.field === col.field);

                if (originalColumn) {
                    Object.keys(originalColumn).forEach((key) => {
                        if (typeof originalColumn[key] === 'function') {
                            col[key] = originalColumn[key];
                        }
                    });
                }

                return col;
            });

        return {
            state: this.state,
            columnsConfig
        };
    }

    private getSavedState(): void {
        this.commonService.settingsService
            .getGridSettings()
            .pipe(
                takeUntil(this.destroy$),
                tapSuccessResult((res) => {
                    this._savedStateExists = res.some(
                        (x) => x.key === this.commonService.getSanitizedUrl(this.gridSettingFilter)
                    );
                })
            )
            .subscribe();
    }

    private loadGridSettingsOnRouteReady(): void {
        // Use combineLatest to wait for both params and queryParams to be fully resolved
        // We have to do this to match the key of the grid settings, because the queryParams(tab=) is not resolved until the route is ready
        combineLatest([this.commonService.activatedRoute.params, this.commonService.activatedRoute.queryParams])
            .pipe(debounceTime(100), takeUntil(this.destroy$))
            .subscribe(() => {
                this.loadGridSettings();
                this.getSavedState();
            });
    }

    private fitColumns(): void {
        this.ngZone.onStable
            .asObservable()
            .pipe(take(1))
            .subscribe(() => setTimeout(() => this.expandLastColumnToFit(), 0));
    }

    private changeSelection() {
        this.selectionKey =
            this.selectBy === 'row'
                ? (context: RowArgs) => context.dataItem
                : (context: RowArgs) => context.dataItem[this.selectBy];
    }

    private processGridData(gridData: any[], state: State) {
        this.gridData = {
            data: process(gridData, state).data,
            total: gridData.length
        };
    }

    private expandLastColumnToFit() {
        const columns = this.grid.columnList.toArray();
        this.grid.autoFitColumns();
        const totalWidthWithoutHidden = columns.reduce((sum, column) => (column.hidden ? sum : sum + column.width), 0);
        const showDetailColumnWidth = this.grid.detailTemplate ? 50 : 0;
        const availableWidth = this.grid.wrapper.nativeElement.offsetWidth - showDetailColumnWidth - 17;
        const widthDelta = availableWidth - totalWidthWithoutHidden;
        if (widthDelta > 0) {
            let i = 1;
            let column = columns.at(-i);
            while (column.hidden) {
                i++;
                column = columns.at(-i);
            }
            column.width += widthDelta;
        }
    }
}
