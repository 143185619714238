import { Injectable, signal } from '@angular/core';
import { RememberStateService } from './remember-state.service';

@Injectable({
    providedIn: 'root'
})
export class CompactModeService {
    isCompactMode = signal(false);

    constructor(private rememberStateService: RememberStateService) {
        // Initialize the compact mode state from the remember state service
        this.isCompactMode.set(this.rememberStateService.get('compactMode') ?? true);
    }

    set(isEnabled: boolean): void {
        this.isCompactMode.set(isEnabled);
        // Save the state in remember state service
        this.rememberStateService.set('compactMode', isEnabled);
    }

    toggle(): void {
        this.set(!this.isCompactMode());
    }

    get(): boolean {
        return this.isCompactMode();
    }
}
