import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { fromUnixTime, isAfter } from 'date-fns';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../models/user';
import { RememberStateService } from './remember-state.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    isAuthenticatedSubject = new BehaviorSubject<boolean>(this.isJwtAuthenticated());
    currentUserSubject = new BehaviorSubject<User>(null);

    constructor(
        private http: HttpClient,
        private router: Router,
        private rememberStateService: RememberStateService
    ) {}

    async login(username: string, password: string, refreshToken = false): Promise<boolean> {
        const data = {
            username,
            password,
            refreshToken
        };

        try {
            const response: AuthTokens = await firstValueFrom(
                this.http.post<any>(`${environment.apiUrl}/command/login`, data)
            );
            this.setAccessToken(response.accessToken);
            this.setRefreshToken(response.refreshToken);
            this.updateIsAuthenticatedSubject(true);
            return true;
        } catch (error) {
            this.updateIsAuthenticatedSubject(false);
            this.updateCurrentUser(null);
            throw error.status === 400 ? error.error.validationErrors[0].errorMessage : 'Unknown error';
        }
    }

    logout(addReturnUrl = true) {
        if (this.hasAccessToken()) {
            this.rememberStateService.clear(['languageId', 'darkMode', 'compactMode']);
        }

        this.updateIsAuthenticatedSubject(false);
        this.updateCurrentUser(null);

        const routerOptions = addReturnUrl ? { queryParams: { returnUrl: this.router.url } } : {};
        return this.router.navigate(['/login'], routerOptions);
    }

    async getCurrentUser(): Promise<any> {
        try {
            const data = await firstValueFrom(this.http.get(`${environment.apiUrl}/query/CurrentUser`));
            const user = data ? new User(data) : null;

            this.updateIsAuthenticatedSubject(!!data);
            this.updateCurrentUser(user);

            return user;
        } catch (error) {
            this.updateIsAuthenticatedSubject(false);
            this.updateCurrentUser(null);

            throw error;
        }
    }

    refreshAccessToken() {
        return this.http
            .post<any>(`${environment.apiUrl}/command/RefreshAccessToken`, {
                refreshToken: this.getRefreshToken()
            })
            .pipe(
                tap((response: AuthTokens) => {
                    this.setAccessToken(response.accessToken);
                    this.setRefreshToken(response.refreshToken);
                })
            );
    }

    getAccessTokenKey() {
        return environment.accessToken;
    }

    getRefreshTokenKey() {
        return environment.refreshToken;
    }

    getAccessToken() {
        return this.rememberStateService.get<string>(this.getAccessTokenKey());
    }

    getRefreshToken() {
        return this.rememberStateService.get<string>(this.getRefreshTokenKey());
    }

    hasAccessToken() {
        return !!this.getAccessToken();
    }

    setAccessToken(accessToken: string) {
        return this.rememberStateService.set(this.getAccessTokenKey(), accessToken);
    }

    setRefreshToken(refreshToken: string) {
        return this.rememberStateService.set(this.getRefreshTokenKey(), refreshToken); //
    }

    getJwtPayload() {
        const token = this.rememberStateService.get(environment.accessToken);
        if (!token) {
            return null;
        }

        return this.parseJwt(token);
    }

    private updateIsAuthenticatedSubject(value) {
        if (this.isAuthenticatedSubject.getValue() !== value) {
            this.isAuthenticatedSubject.next(value);
        }
    }

    private updateCurrentUser(user) {
        if (this.currentUserSubject.getValue() !== user) {
            this.currentUserSubject.next(user);
        }
    }

    private parseJwt(token): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    private isJwtAuthenticated() {
        try {
            const payload = this.getJwtPayload();

            if (!payload) {
                return false;
            }

            const expireDate = fromUnixTime(payload.exp);

            return isAfter(expireDate, new Date());
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }
}

export type AuthTokens = {
    accessToken: string;
    refreshToken: string;
};
