<app-content-header title="{{ 'Notifications' | translate }}" icon="faSolidBell" [actionBar]="actionBar">
    <div subtitle>{{ subtitle }}</div>
</app-content-header>
<div setEditMode isEditMode="true">
    <app-drawer [toggle]="filterOpen" (search)="search()" (clearFilter)="clearFilter()">
        <app-datetime-input
            label="{{ 'Valid From' | translate }}"
            id="validFromDate"
            [(value)]="filter.validFromDate" />
        <app-datetime-input
            label="{{ 'Valid Until' | translate }}"
            id="validUntilDate"
            [(value)]="filter.validUntilDate" />
    </app-drawer>
</div>
<kendo-listview
    class="h-full rounded-md border-0 bg-white shadow-md dark:bg-neutral-600"
    #listView
    [data]="data.length > 0 ? data : [null]"
    [loading]="loading"
    (scrollBottom)="loadMore()">
    <ng-template kendoListViewHeaderTemplate>
        <div class="inline-flex w-full justify-between">
            <div class="mt-6">
                <kendo-tabstrip class="custom-tabstrip" (tabSelect)="onTabSelect($event)">
                    @for (tab of tabs; track $index) {
                        <kendo-tabstrip-tab
                            title="{{ tab.title | translate }}"
                            [selected]="tab.value === currentTab"></kendo-tabstrip-tab>
                    }
                </kendo-tabstrip>
            </div>
            <div class="flex p-3">
                @if (canSeeList) {
                    <div class="inline-flex items-end">
                        <app-button
                            tooltip="{{ 'Filter' | translate }}"
                            icon="faSolidFilter"
                            [variant]="nrOfActiveFilters ? 'primary' : 'light'"
                            (clicked)="toggleFilter()">
                            @if (nrOfActiveFilters) {
                                <span class="leading-3">{{ nrOfActiveFilters }}</span>
                            }
                        </app-button>
                        @if (nrOfActiveFilters) {
                            <app-button
                                class="ml-1"
                                tooltip="{{ 'Clear Filter' | translate }}"
                                variant="light"
                                icon="faSolidXmark"
                                (clicked)="clearFilter()"></app-button>
                        }
                    </div>
                }
            </div>
        </div>
    </ng-template>
    <ng-template kendoListViewItemTemplate let-dataItem>
        @if (data.length) {
            <kendo-expansionpanel
                class="group relative m-2 rounded-xl border-0 p-0 ring-0 ring-red-200"
                [ngClass]="{
                    'list-disc': dataItem.important,
                    'bg-blue-100': !dataItem.seen && dataItem.active,
                    'bg-popover': dataItem.seen || !dataItem.active
                }"
                (expandedChange)="expandedChange($event, dataItem)">
                <ng-template kendoExpansionPanelTitleDirective>
                    <div class="flex w-full flex-col flex-wrap">
                        <div
                            class="flex w-full justify-between"
                            [ngClass]="{
                                'dark:text-white': !(!dataItem.seen && dataItem.active)
                            }">
                            <div
                                class="mr-8 flex gap-1 font-bold text-gray-700"
                                [ngClass]="{
                                    'truncate text-ellipsis': !expandedStates[dataItem.id],
                                    'dark:text-white': !(!dataItem.seen && dataItem.active)
                                }">
                                {{ dataItem.title }}
                                @if (isModerator) {
                                    <div class="edit-buttons">
                                        <span
                                            [ngClass]="{
                                                'dark:text-white': !(!dataItem.seen && dataItem.active)
                                            }">
                                            <app-button
                                                tooltip="{{ 'Edit' | translate }}"
                                                icon="faSolidPenToSquare"
                                                size="sm"
                                                variant="link"
                                                (clicked)="navigateToNotification(dataItem.id)" />
                                        </span>
                                        <span>
                                            <app-button
                                                class="bg-transparent hover:text-danger"
                                                tooltip="{{ 'Remove' | translate }}"
                                                icon="faSolidTrash"
                                                variant="link"
                                                size="sm"
                                                (clicked)="deactivateNotification(dataItem)" />
                                        </span>
                                    </div>
                                }
                            </div>
                            <div class="flex flex-wrap items-center gap-2 whitespace-nowrap text-xs text-muted">
                                @if (dataItem.validFromDate || dataItem.validUntilDate) {
                                    <div
                                        class="rounded-md bg-info/60 px-2 text-info-foreground"
                                        kendoTooltip
                                        [title]="getTimeLimitTooltip(dataItem)">
                                        {{ 'Time limited' | translate }}
                                    </div>
                                }
                                @if (dataItem.important) {
                                    <div class="rounded-md bg-danger/60 px-2 text-danger-foreground">
                                        {{ 'Important' | translate }}
                                    </div>
                                }
                                <div
                                    class="right-content text-right font-semibold text-gray-700"
                                    [ngClass]="{
                                        'dark:text-white': !(!dataItem.seen && dataItem.active)
                                    }">
                                    {{ dataItem.createdByFullName }}
                                </div>
                                <div
                                    class="whitespace-nowrap text-gray-800"
                                    [ngClass]="{
                                        'dark:text-white': !(!dataItem.seen && dataItem.active)
                                    }">
                                    <span kendoTooltip title="{{ 'Created Date' | translate }}">
                                        {{ dataItem.createdDate | date: 'dd.MM HH:mm' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="text-sm text-gray-500"
                            [ngClass]="{
                                'dark:text-white': !(!dataItem.seen && dataItem.active)
                            }">
                            <div class="flex items-center justify-between">
                                <span
                                    class="mr-3"
                                    [ngClass]="expandedStates[dataItem.id] ? 'opacity-0' : 'opacity-100'"
                                    [innerHtml]="displayFirstSentence(dataItem?.content)"></span>
                                @if (dataItem.attachments?.length) {
                                    <app-button
                                        tooltip="{{ 'Attachment' | translate }}"
                                        icon="faSolidPaperclip"
                                        [variant]="'link'">
                                        <span class="-ml-1 text-xs">{{ dataItem.attachments.length }}</span>
                                    </app-button>
                                } @else {
                                    <div class="h-8 w-10"></div>
                                }
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div
                    class="p-0 text-gray-800"
                    [ngClass]="{
                        'dark:text-white': !(!dataItem.seen && dataItem.active)
                    }">
                    <p [innerHtml]="dataItem.content"></p>
                    @for (attachment of dataItem.attachments; track $index) {
                        <div class="mt-1">
                            <ng-icon
                                class="mr-2 text-blue-900"
                                kendoTooltip
                                title="{{ 'Attachment' | translate }}"
                                name="faSolidPaperclip"
                                [ngClass]="{
                                    'dark:text-link': !(!dataItem.seen && dataItem.active)
                                }" />
                            <a
                                class="text-blue-900"
                                href="#"
                                [ngClass]="{
                                    'dark:text-link': !(!dataItem.seen && dataItem.active)
                                }"
                                (click)="previewAttachment($event, attachment.file.id)">
                                {{ attachment.file.name }}
                            </a>
                        </div>
                    }
                </div>
            </kendo-expansionpanel>
        } @else {
            @if (!loading) {
                <h2 class="m-9 text-center text-muted">{{ 'No notices available at this time.' | translate }}</h2>
            }
        }
    </ng-template>
    <ng-template kendoListViewFooterTemplate></ng-template>
    <ng-template kendoListViewLoaderTemplate>
        <div class="flex h-40 items-center justify-center">
            <app-loader [isBusy]="loading"></app-loader>
        </div>
    </ng-template>
</kendo-listview>
