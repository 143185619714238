import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/setting-key.codes';
import { SelfRegistrationModalComponent } from '@common/modals/self-registration-modal/self-registration-modal.component';
import { CommonService } from '@common/services/common.service';
import { environment } from '@environments/environment';
@Component({
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, AfterViewInit {
    username: string;
    password: string;
    errorMessage: string;
    currentLanguage = this.commonService.i18nService.currentLanguage;
    isBusy = false;
    environment = environment.name;
    settings = environment.settings;
    environmentColor = environment.settings.header.environmentColor;
    title = environment.title;
    logo = environment.settings.header.logo;
    languages = this.commonService.i18nService.languages;
    version = environment.version;

    showTermsAndConditions = false;
    showRegister = false;
    showForgetPassword = false;
    showLanguageFlag = false;
    certificateLogin = false;
    googleLogin = false;
    appleLogin = false;
    facebookLogin = false;

    constructor(private commonService: CommonService) {
        commonService.userService.isAuthenticatedSubject.next(false);
    }

    ngOnInit(): void {
        this.commonService.rememberStateService.clear(['languageId', 'darkMode', 'compactMode']);
    }

    ngAfterViewInit() {
        this.getAppSettings();
    }

    toggleTheme() {
        this.commonService.darkModeService.toggle();
    }

    onSubmit() {
        this.signIn();
    }

    isDarkTheme() {
        return this.commonService.darkModeService.get();
    }

    async openRegistrationWizard() {
        const saveModel = await this.commonService.dialogService.open({
            template: { content: SelfRegistrationModalComponent },
            dialogSettings: {
                width: '50vw',
                minWidth: '360px'
            }
        });
        if (!saveModel) return;

        this.commonService.executeCommand({
            command: 'SelfRegister',
            data: { saveModel },
            customCallback: () => this.commonService.router.navigateByUrl('/login'),
            successToastrMessage: 'User was successfully created and is waiting for admin approval'
        });
    }

    private signIn() {
        this.isBusy = true;
        this.errorMessage = null;
        const returnUrl = this.commonService.router.routerState.snapshot.root.queryParams.returnUrl ?? '/';

        if (!this.username || !this.password) {
            this.commonService.toastrNotificationService.show({
                type: 'error',
                message: 'Username and Password are required fields',
                title: 'Error'
            });
            this.isBusy = false;
        } else {
            this.commonService.userService
                .login(this.username, this.password)
                .then(() => {
                    this.commonService.router.navigateByUrl(returnUrl);
                    this.getActiveNotifications();
                    this.commonService.queryService.queryClient.invalidateQueries({ queryKey: ['settings'] });
                })
                .catch((errorMessage) => (this.errorMessage = errorMessage))
                .finally(() => (this.isBusy = false));
        }
    }

    private async getActiveNotifications() {
        const res = await this.commonService.queryService.query('HasActiveNotifications', { important: true });
        if (!res) return;
        this.commonService.toastrNotificationService
            .show(
                {
                    type: 'info',
                    message: 'You have unread notifications. Click here to view them.',
                    title: 'Notifications'
                },
                {
                    timeOut: 10_000,
                    closeButton: true,
                    tapToDismiss: false,
                    progressBar: true
                }
            )
            .onTap.subscribe(() => {
                this.commonService.router.navigate(['/notice-board/notification/list'], {
                    queryParams: { tab: 'All' }
                });
            });
    }

    private getAppSettings() {
        this.commonService.queryService.queryClient
            .ensureQueryData({ queryKey: ['loginSettings'] })
            .then((result: Setting[]) => {
                this.showTermsAndConditions = this.getSettingValueBoolean(result, SettingKeyCodes.TermsAndConditions);
                this.showRegister = this.getSettingValueBoolean(result, SettingKeyCodes.UserRegistration);
                this.showForgetPassword = this.getSettingValueBoolean(result, SettingKeyCodes.ForgetPassword);
                this.showLanguageFlag = this.getSettingValueBoolean(result, SettingKeyCodes.LanguageFlag);
                this.certificateLogin = this.getSettingValueBoolean(result, SettingKeyCodes.CertificateLogin);
                this.googleLogin = this.getSettingValueBoolean(result, SettingKeyCodes.GoogleLogin);
                this.appleLogin = this.getSettingValueBoolean(result, SettingKeyCodes.AppleLogin);
                this.facebookLogin = this.getSettingValueBoolean(result, SettingKeyCodes.FacebookLogin);
                this.title = this.getSettingValue(result, SettingKeyCodes.LongCompanyName);
                const companyLogo = result?.find((s: Setting) => s.key === SettingKeyCodes.Logo);
                if (companyLogo) this.handleLogo(companyLogo.value);
            });
    }

    private getSettingValue(result: Setting[], settingKey: SettingKeyCodes): string | undefined {
        const setting = result?.find((s: Setting) => s.key === settingKey);
        return setting?.value;
    }

    private getSettingValueBoolean(result: Setting[], settingKey: SettingKeyCodes): boolean {
        const value = this.getSettingValue(result, settingKey);
        return value === 'true';
    }

    private handleLogo(imageContent: string) {
        const decodedData = atob(imageContent);
        const uint8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
            uint8Array[i] = decodedData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array]);
        this.logo = URL.createObjectURL(blob);
    }
}
