@if (!isSidebarExpanded) {
    <div class="flex flex-col">
        <div class="mb-2 flex flex-col gap-1">
            <div class="flex justify-center">
                <app-button
                    type="button"
                    tooltip="User profile"
                    variant="link"
                    icon="faSolidCircleUser"
                    (clicked)="togglePinSidebar.emit()" />
            </div>
            <div class="flex justify-center">
                <app-button
                    type="button"
                    tooltip="Sign Out"
                    variant="link"
                    icon="faSolidArrowRightFromBracket"
                    (clicked)="logout()"></app-button>
            </div>
        </div>
    </div>
}
<div
    [@fade]="isSidebarExpanded ? 'show' : 'hide'"
    [ngClass]="{
        block: isSidebarExpanded,
        hidden: !isSidebarExpanded,
        'flex flex-col gap-2 text-sm': true
    }">
    <div class="flex flex-nowrap justify-between gap-2 px-2">
        <app-button
            tooltip="{{ 'User settings' | translate }}"
            variant="link"
            icon="faSolidGear"
            (clicked)="navigateToUserSettings()" />
        <!-- TODO: Apply permission for upload image button -->
        <app-button tooltip="Upload background" variant="link" icon="faSolidUpload" (clicked)="uploadImage.emit()" />
        <app-button
            *ngIf="darkTheme"
            tooltip="Light mode"
            variant="link"
            icon="faSolidSun"
            (clicked)="toggleTheme.emit()" />
        <app-button
            *ngIf="!darkTheme"
            tooltip="Dark mode"
            variant="link"
            icon="faSolidMoon"
            (clicked)="toggleTheme.emit()" />
        <app-button
            *ngIf="isCompactMode()"
            tooltip="Expand grid"
            variant="link"
            icon="faSolidExpand"
            (clicked)="toggleCompactMode()" />
        <app-button
            *ngIf="!isCompactMode()"
            tooltip="Compress grid"
            variant="link"
            icon="faSolidCompress"
            (clicked)="toggleCompactMode()" />
        <div placement="bottom-right" kendoTooltip title="{{ 'Change Language' | translate }}">
            @if (currentLanguage()) {
                <kendo-dropdownbutton
                    buttonClass="bg-card text-card-foreground border-none transition-none hover:bg-primary/20 bg-none"
                    [data]="languages">
                    @if (languageFlag) {
                        <span class="p-0" [ngClass]="currentLanguage().iconClass"></span>
                    } @else {
                        {{ currentLanguage().languageId | uppercase }}
                    }
                </kendo-dropdownbutton>
            }
        </div>
    </div>
    <hr class="border-background-darker" />
    <div class="my-1 ml-2 mr-2 flex min-h-10 items-center justify-between overflow-ellipsis">
        <a
            class="flex items-center gap-2 break-words rounded-md px-2 py-1 text-sm text-card-foreground hover:bg-primary/20"
            [routerLink]="['user-account/view', currentUser?.id]">
            <ng-icon class="shrink-0 text-4xl hover:text-primary" name="faSolidCircleUser" />
            <div>
                {{ currentUser?.fullName ? currentUser?.fullName : currentUser?.userName }}
            </div>
        </a>
        <app-button variant="link" icon="faSolidArrowRightFromBracket" tooltip="Sign Out" (clicked)="logout()" />
    </div>
</div>
