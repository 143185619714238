import { Component, Input, OnInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { NumberInputConfig } from '../input.type';

/**
 * Number input component.
 * @param min - The minimum number.
 * @param max - The maximum number.
 * @param format - The format of the number.
 * @param decimals - The number of decimals.
 * @param maxLength - The maximum length of the number.
 * @param showSpinners - Whether to show spinners(Up and Down arrows) in edit mode.
 */
@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: NumberInputComponent }]
})
export class NumberInputComponent extends BaseInputComponent<number> implements OnInit {
    @Input() min: NumberInputConfig['min'] = 0;
    @Input() max: NumberInputConfig['max'];
    @Input() format: NumberInputConfig['format'] = '#';
    @Input() decimals: NumberInputConfig['decimals'];
    @Input() maxLength: NumberInputConfig['maxLength'];
    @Input() showSpinners: NumberInputConfig['showSpinners'] = false;

    viewNumberFormat: string;

    ngOnInit(): void {
        // have to check for undefined as 0 is a valid number
        if (this.decimals !== undefined) {
            this.format = `n${this.decimals}`;
            this.viewNumberFormat = `1.${this.decimals}-${this.decimals}`;
        }
    }
}
