import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, ActivationStart, NavigationEnd } from '@angular/router';
import { fade } from '@common/animations/fade';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/setting-key.codes';
import { User } from '@common/models/user';
import { CommonService } from '@common/services/common.service';
import { UserService } from '@common/services/user.service';
import { WebsocketService } from '@common/services/websocket.service';
import { environment } from '@environments/environment';
import { tapSuccessResult } from '@ngneat/query';
import { NAVIGATION, NavItem } from 'app/navigation';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    width: '16rem'
                })
            ),
            state(
                'closed',
                style({
                    width: '3rem'
                })
            ),
            transition('open => closed', [
                group([
                    query('@fade', [animateChild()]),
                    query('@alignLogo', [animateChild()]),
                    animate('0.3s ease-out')
                ])
            ]),
            transition('closed => open', [
                group([
                    query('@fade', [animateChild()]),
                    query('@alignLogo', [animateChild()]),
                    animate('0.3s ease-out')
                ])
            ])
        ]),
        fade,
        trigger('alignLogo', [
            state('show', style({})),
            state(
                'hide',
                style({
                    marginTop: '1.75rem'
                })
            ),
            transition('show => hide', [animate('300ms ease-out')]),
            transition('hide => show', [animate('300ms ease-in')])
        ])
    ]
})
export class SidebarComponent implements OnDestroy {
    destroy$ = new Subject<boolean>();
    isSidebarExpanded: boolean;
    environmentTitle = environment.title;
    environmentLogo = environment.settings.header.logo;
    navigation = NAVIGATION;
    currentUser: User;

    constructor(
        websocketService: WebsocketService,
        private title: Title,
        private commonService: CommonService,
        userService: UserService
    ) {
        const sidebarPinned = this.commonService.rememberStateService.get<boolean>('sidebarPinned');
        if (sidebarPinned === undefined) this.setSidebarPinned(true);
        this.isSidebarExpanded = sidebarPinned ?? true;

        userService.currentUserSubject.subscribe((user) => {
            this.currentUser = user;
            this.filterNavigationItems();
        });

        commonService.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                websocketService.info(`Navigated to ${event.url}`);
                const title = this.getDeepestTitle(this.commonService.router.routerState.snapshot.root);
                this.title.setTitle(title ? `${title} · ${environment.title}` : `${environment.title}`);
                // if there are not selected items, get the url and select the item

                this.activateNavItems(this.navigation);
            }
        });

        commonService.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof ActivationStart &&
                        event.snapshot.data.permissions &&
                        (!Array.isArray(event.snapshot.data.permissions) || event.snapshot.data.permissions.length > 0)
                )
            )
            .subscribe((event) => {
                const { permissions } = (event as ActivationStart).snapshot.data;

                if (!this.currentUser.hasPermission(permissions)) {
                    console.dir(event);
                    console.error('Authorization failed.', this.currentUser, permissions);
                    websocketService.error(
                        `Authorization for ${this.getResolvedUrl((event as ActivationStart).snapshot)} failed.`
                    );
                    this.commonService.toastrNotificationService.show({ type: 'error', message: 'Unauthorized' });

                    this.commonService.router.navigate(['/401'], {
                        queryParams: { returnUrl: this.getResolvedUrl((event as ActivationStart).snapshot) }
                    });
                }
            });

        this.getSettings();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    filterNavigationItems(): void {
        if (!this.currentUser) return;
        this.navigation = NAVIGATION.map((nav) => ({ ...nav })).filter((item: NavItem) => this.filterNavItem(item));
    }

    toggleExpanded() {
        this.isSidebarExpanded = !this.isSidebarExpanded;
        this.setSidebarPinned(this.isSidebarExpanded);
    }

    toggleTheme() {
        this.commonService.darkModeService.toggle();
    }

    toggleItemExpanded(clickedItem: NavItem) {
        const newNavigation = this.navigation.map((item) => {
            // Clicking on disabled menu item does nothing
            if (clickedItem.disabled) return item;
            // As addition to Kendo UI PanelBar logic:
            // Collapse item if childless item is clicked
            if (!clickedItem.children) item.expanded = false;
            // Expand item if item is clicked, colapse all other items
            item.expanded = item === clickedItem;
            return item;
        });
        this.navigation = newNavigation;
    }

    openUploadImageDialog() {
        this.commonService.dialogService
            .form({
                options: {
                    title: 'Upload background image',
                    properties: [
                        {
                            label: 'Background image',
                            name: 'image',
                            type: 'file',
                            allowedExtensions: ['jpg', 'png'],
                            required: true
                        }
                    ],
                    canConfirm: (model) => model.image
                }
            })
            .then((data: any) => {
                if (!data) return;
                this.commonService.queryService
                    .command('SaveBackgroundImage', {
                        content: data.image.content,
                        name: data.image.name
                    })
                    .then((res: any) => {
                        if (!res) return;
                        // Maybe write a separate query to get the image only, not all settings
                        this.commonService.queryService.queryClient.invalidateQueries();
                        this.commonService.toastrNotificationService.show({
                            type: 'success',
                            message: 'Background image uploaded successfully'
                        });
                    });
            });
    }

    isDarkTheme() {
        return this.commonService.darkModeService.get();
    }

    private filterNavItem(navItem: NavItem): boolean {
        if (!this.canUserAccessNavItem(navItem) || navItem.hidden) return false;

        if (navItem.children) navItem.children = navItem.children.filter((child) => this.filterNavItem(child));

        return true;
    }

    private canUserAccessNavItem(navItem: NavItem): boolean {
        if (!navItem.permissions || this.currentUser.isSystemUser) return true;

        const permissions = Array.isArray(navItem.permissions) ? navItem.permissions : [navItem.permissions];
        return this.currentUser.hasPermission(...permissions);
    }

    private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title = routeSnapshot.data ? routeSnapshot.data.title : '';

        if (routeSnapshot.firstChild) {
            title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
        }

        return title;
    }

    private getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
    }

    private activateNavItems(data: NavItem[]) {
        data.forEach((item) => {
            if (item.children?.length > 0) {
                item.children.forEach((child) => {
                    if (
                        this.commonService.router.isActive(child.path, {
                            matrixParams: 'ignored',
                            queryParams: 'ignored',
                            paths: 'subset',
                            fragment: 'ignored'
                        })
                    ) {
                        item.expanded = true;
                        child.selected = true;
                    } else {
                        child.selected = false;
                    }
                });
            } else {
                item.selected = this.commonService.router.isActive(item.path, {
                    matrixParams: 'ignored',
                    queryParams: 'ignored',
                    paths: 'exact',
                    fragment: 'ignored'
                });
            }
        });
    }

    private setSidebarPinned(value: boolean) {
        this.commonService.rememberStateService.set('sidebarPinned', value);
    }

    private getSettings() {
        this.commonService.settingsService
            .getSettings()
            .pipe(
                takeUntil(this.destroy$),
                tapSuccessResult((result: any) => {
                    if (!result) return;
                    const title = result.find(
                        (setting: Setting) => setting.key === SettingKeyCodes.LongCompanyName
                    )?.value;
                    if (title) this.environmentTitle = title;
                    const companyLogo = result.find((setting: Setting) => setting.key === SettingKeyCodes.Logo);
                    if (companyLogo?.value) this.handleLogo(companyLogo.value);
                    else this.environmentLogo = environment.settings.header.logo;
                })
            )
            .subscribe();
    }

    private handleLogo(imageContent: string) {
        const decodedData = atob(imageContent);
        const uint8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
            uint8Array[i] = decodedData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array]);
        this.environmentLogo = URL.createObjectURL(blob);
    }
}
